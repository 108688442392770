<template>
  <v-container fluid class="mt-12 py-12 blue-grey lighten-5">
    <v-row>
      <v-col cols="12">
        <h1 class="text-center secondary--text mb-6">Nuestros valores</h1>
      </v-col>
    </v-row>
    <v-row align="stretch" justify="center">
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        v-for="(item, index) in values"
        :key="index"
        class="d-flex"
      >
        <v-card
          class="py-6 secondary flex d-flex flex-column rounded-lg"
          outlined
        >
          <v-card-text class="flex px-8">
            <h2
              style="font-size: 1.8rem; line-height: 2.1rem"
              class="mt-6 mb-6 white--text"
            >
              {{ item.text }}
            </h2>
            <h3 class="font-weight-regular white--text">
              {{ item.desc }}
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    values: [
      {
        text: "Disciplina",
        desc: "La disciplina nos lleva a cumplir con nuestras normas, además refleja con acciones nuestro trabajo y puntualidad.",
      },
      {
        text: "Orden",
        desc: "En todos sentidos manejamos el orden ya que sabemos el rumbo que hemos tomado.",
      },

      {
        text: "Autorregulación",
        desc: "Practicamos la inteligencia emocional y la autodisciplina aun en situaciones difíciles.",
      },

      {
        text: "Cumplir con lo que se promete",
        desc: "Tratamos a nuestros colaboradores, socios, proveedores, clientes e inversionistas, como nos gustaría que nos trataran.",
      },

      {
        text: "Responsabilidad",
        desc: "Nos moderamos en nuestra comunicación, por que aun que esta empresa esta hecha para generar dinero colaboradores, socios, proveedores, clientes e inversionistas, no todo es si, por cerrar una venta, volviendo al principio del respeto al derecho ajeno.",
      },
    ],
  }),
};
</script>